import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import { selectPreviousStockTakeItemInformation } from "../previousStockTakeItemInformationSelectors";
import { previousStockTakeItemInformationRequired } from "../previousStockTakeItemInformationSlice";

import { LastStockTakeDate } from "./LastStockTakeDate";

export interface PreviousStockTakeItemInformationProps {
  itemId: string;
  stockTakeVivaldiId: number;
  toDateTimeUtc: string;
  "data-cy"?: string;
}

export const PreviousStockTakeItemInformation: React.FC<PreviousStockTakeItemInformationProps> =
  function ({ itemId, stockTakeVivaldiId, toDateTimeUtc, "data-cy": dataCy }) {
    const dispatch = useDispatch();
    const stockId = useSelector(selectWarehouseId);
    const stockTakeItemHistory = useSelector(
      selectPreviousStockTakeItemInformation(
        stockId,
        stockTakeVivaldiId,
        itemId
      )
    );

    useEffect(() => {
      if (!stockTakeItemHistory) {
        dispatch(
          previousStockTakeItemInformationRequired({
            itemId,
            stockTakeVivaldiId,
            stockId,
            toDateTimeUtc,
          })
        );
      }
    }, [
      dispatch,
      itemId,
      stockTakeVivaldiId,
      stockId,
      toDateTimeUtc,
      stockTakeItemHistory,
    ]);

    if (!stockTakeItemHistory) {
      return null;
    }

    if (stockTakeItemHistory.state === "idle") {
      return (
        <div data-cy={dataCy}>
          <LastStockTakeDate
            lastStockTakeDate={stockTakeItemHistory.stockTakeDateTimeUtc}
            className="flex justify-between"
          />
        </div>
      );
    }

    return null;
  };
