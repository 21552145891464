import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ViewComponentProps } from "../../routing/components/View";
import routes from "../../routing/routes";
import useNavigate from "../../routing/useNavigate";
import {
  selectManualStockTakingOpenItem,
  selectManualStockTakingStatus,
  selectItems,
} from "../manualStockTakingSelectors";
import { manualStockTakingItemRequired } from "../manualStockTakingSlice";

import ManualStockTakingItemErrorModal from "./ManualStockTakingItemErrorModal";
import ManualStockTakingItemLoading from "./ManualStockTakingItemLoading";
import ManualStockTakingValidItem from "./ManualStockTakingValidItem";

type ManualStockTakingItemParams = {
  itemIdOrBarcode: string;
  barcode: boolean;
};

const ManualStockTakingItem: React.FC<
  ViewComponentProps<ManualStockTakingItemParams>
> = function ({ params }) {
  const navigate = useNavigate();
  const status = useSelector(selectManualStockTakingStatus);
  const openItem = useSelector(selectManualStockTakingOpenItem);
  const items = useSelector(selectItems);
  const dispatch = useDispatch();

  const { itemIdOrBarcode, barcode } = params;

  const handleClose = () => navigate.back();

  useEffect(() => {
    if (status === "idle") {
      dispatch(
        manualStockTakingItemRequired(
          barcode ? { barcode: itemIdOrBarcode } : { itemId: itemIdOrBarcode }
        )
      );
    }
  });

  if (status === "loadingItem") {
    return <ManualStockTakingItemLoading onClose={handleClose} />;
  }

  if (!openItem) {
    return null;
  }

  if (openItem.invalidItem || openItem.error) {
    return (
      <ManualStockTakingItemErrorModal
        item={openItem.invalidItem}
        bestEffortItemId={openItem.itemId}
        enableRemove={items.some((x) => x.itemId === openItem.itemId)}
        overrideError={openItem.error}
        onClose={handleClose}
        onRemove={() => {
          navigate(routes.stockTaking.manual.item.remove, {
            itemIdOrBarcode: openItem.itemId,
          });
        }}
      />
    );
  }

  if (!openItem.validItem) {
    return null;
  }

  return <ManualStockTakingValidItem item={openItem.validItem} />;
};

export default ManualStockTakingItem;
