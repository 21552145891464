import { ItemStockTakeHistorySummary } from "@ahlsell-group/store20-stock-taking-service";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PreviousStockTakeItemInformationState {
  items: Record<string, PreviousStockTakeItemInformationWithState>;
}

export type PreviousStockTakeItemInformation = ItemStockTakeHistorySummary & {
  perFormedOnStockTakeVivaldiId: number;
};

export type PreviousStockTakeItemInformationWithState =
  | ({ state: "idle" } & PreviousStockTakeItemInformation)
  | ({
      state: "loading";
    } & Partial<PreviousStockTakeItemInformation>);

export interface PreviousStockTakeItemInformationRequiredPayload {
  itemId: string;
  stockId: number;
  stockTakeVivaldiId: number;
  toDateTimeUtc: string;
}

export interface PreviousStockTakeItemInformationReceivedPayload
  extends PreviousStockTakeItemInformation {
  stockId: number;
}

const slice = createSlice({
  name: "previousStockTakeItemInformation",
  initialState: {
    items: {},
  } satisfies PreviousStockTakeItemInformationState as PreviousStockTakeItemInformationState,
  reducers: {
    previousStockTakeItemInformationRequired: (
      state,
      {
        payload,
      }: PayloadAction<PreviousStockTakeItemInformationRequiredPayload>
    ) => {
      const { itemId, stockId, stockTakeVivaldiId } = payload;
      state.items[`${stockId}-${stockTakeVivaldiId}-${itemId}`] ??= {
        state: "loading",
      };
    },
    previousStockTakeItemInformationReceived: (
      state,
      {
        payload,
      }: PayloadAction<PreviousStockTakeItemInformationReceivedPayload>
    ) => {
      const { itemId, stockId, stockTakeVivaldiId, ...rest } = payload;
      state.items[`${stockId}-${stockTakeVivaldiId}-${itemId}`] = {
        state: "idle",
        itemId,
        stockTakeVivaldiId,
        ...rest,
      };
    },
  },
});

export const {
  previousStockTakeItemInformationRequired,
  previousStockTakeItemInformationReceived,
} = slice.actions;
export const { reducer: previousStockTakeItemInformationReducer } = slice;
