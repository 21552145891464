import { all, takeEvery, takeLatest } from "redux-saga/effects";

import { appStarted } from "../../app/appSlice";
import { ServiceContainer } from "../../app/serviceContainer";
import clearUserAndReloadSaga from "../warehouse/sagas/clearUserAndReloadSaga";
import { reloadForWarehouseUpdateRequested } from "../warehouse/warehouseSlice";

import {
  deleteAllItems,
  deleteItem,
  updateItem,
  submitStockTakesRequested,
  submitStockTakesSucceeded,
  initStockTake,
  deleteItemFailed,
  deleteAllItemsFailed,
  updateItemFailed,
  itemLoadFailed,
  reviewStockTake,
  manualStockTakingItemRequired,
} from "./manualStockTakingSlice";
import { previousStockTakeItemInformationRequired } from "./previousStockTakeItemInformationSlice";
import { deleteAllItemsSaga } from "./sagas/deleteAllItemsSaga";
import { deleteItemSaga } from "./sagas/deleteItemSaga";
import { getItemSaga } from "./sagas/getItemSaga";
import { getPreviousStockTakeItemInformationSaga } from "./sagas/getPreviousStockTakeItemInformationSaga";
import { getSubmittedStockTakesSaga } from "./sagas/getSubmittedStockTakesSaga";
import { initializeManualStockTakingSaga } from "./sagas/initializeManualStockTakingSaga";
import { reviewStockTakeSaga } from "./sagas/reviewStockTakeSaga";
import { submitStockTakeSaga } from "./sagas/submitStockTakeSaga";
import { updateItemStockTakingQuantitySaga } from "./sagas/updateItemStockTakingQuantitySaga";
import { submissionsRequested } from "./stockTakenSubmissionSlice";

export default function* stockTakingSagas(serviceContainer: ServiceContainer) {
  yield all([
    takeLatest(
      manualStockTakingItemRequired.type,
      getItemSaga,
      serviceContainer
    ),
    takeEvery(
      submitStockTakesRequested.type,
      submitStockTakeSaga,
      serviceContainer
    ),

    takeEvery(
      updateItem.type,
      updateItemStockTakingQuantitySaga,
      serviceContainer
    ),
    takeEvery(deleteItem.type, deleteItemSaga, serviceContainer),
    takeEvery(deleteAllItems.type, deleteAllItemsSaga, serviceContainer),
    takeEvery(reviewStockTake.type, reviewStockTakeSaga, serviceContainer),
    takeEvery(
      submissionsRequested.type,
      getSubmittedStockTakesSaga,
      serviceContainer
    ),
    takeEvery(
      previousStockTakeItemInformationRequired.type,
      getPreviousStockTakeItemInformationSaga,
      serviceContainer
    ),

    takeEvery(
      [
        // When the app started and when a new initialization is explicitly requested.
        appStarted.type,
        initStockTake.type,

        // When the stock-take is no longer the current one.
        submitStockTakesSucceeded.type,

        // Errors may occur because of stale data. Refresh the data upon various errors.
        itemLoadFailed.type,
        updateItemFailed.type,
        deleteItemFailed.type,
        deleteAllItemsFailed.type,
      ],
      initializeManualStockTakingSaga,
      serviceContainer
    ),

    takeLatest(
      reloadForWarehouseUpdateRequested.type,
      clearUserAndReloadSaga,
      serviceContainer
    ),
  ]);
}
