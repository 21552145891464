import { ItemStockTakeHistorySummary } from "@ahlsell-group/store20-stock-taking-service";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import ActionType from "../../../util/ActionType";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  previousStockTakeItemInformationReceived,
  previousStockTakeItemInformationRequired,
} from "../previousStockTakeItemInformationSlice";

export function* getPreviousStockTakeItemInformationSaga(
  serviceContainer: ServiceContainer,
  action: ActionType<typeof previousStockTakeItemInformationRequired>
): Saga {
  const { stockTakingService, handleSagaError } = serviceContainer;

  try {
    const stockId: number = yield select(selectWarehouseId);

    const previousStockTakeItems: ItemStockTakeHistorySummary[] = yield call(
      [stockTakingService, "getItemStockTakeHistory"],
      stockId,
      action.payload.itemId,
      action.payload.toDateTimeUtc,
      1
    );

    if (previousStockTakeItems.length > 0) {
      const { stockTakeVivaldiId, ...rest } = previousStockTakeItems[0];

      yield put(
        previousStockTakeItemInformationReceived({
          stockId,
          stockTakeVivaldiId: action.payload.stockTakeVivaldiId,
          perFormedOnStockTakeVivaldiId: stockTakeVivaldiId,
          ...rest,
        })
      );
    }
  } catch (e) {
    yield call(
      handleSagaError,
      (errorCode) =>
        `saga:stock-taking:getPreviousStockTakeItemInformationSaga:${errorCode}`,
      e
    );
  }
}
