import { useTranslation } from "@ahlsell-group/app-localization";
import React from "react";

import { getDate, getDaysSince } from "../util/date";

export interface LastStockTakeDateProps
  extends React.HTMLAttributes<HTMLDivElement> {
  lastStockTakeDate: string;
}

export const LastStockTakeDate: React.FC<LastStockTakeDateProps> = function ({
  lastStockTakeDate,
  ...rest
}) {
  const { t } = useTranslation("common");
  const getText = (date: string) => {
    const daysSince = getDaysSince(date);

    if (daysSince === 0) {
      return t("today");
    }
    if (daysSince === 1) {
      return t("yesterday");
    }
    if (daysSince <= 14) {
      return t("stockTaking.daysAgo", { count: daysSince });
    }
    return getDate(date);
  };

  return (
    <div {...rest}>
      <span>{t("stockTaking.lastStockTake")}</span>{" "}
      <span>{getText(lastStockTakeDate)}</span>
    </div>
  );
};
