import { GetItemResponse } from "@ahlsell-group/store20-stock-taking-service";
import { call, put, select } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import ActionType from "../../../util/ActionType";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import getItemIdByBarcode from "../../item-information/sagas/getItemIdByBarcode";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  itemLoaded,
  itemLoadFailed,
  itemLoadInvalidBarcode,
  manualStockTakingItemRequired,
} from "../manualStockTakingSlice";

import { getCurrentStockTakeId } from "./getCurrentStockTakeId";

export function* getItemSaga(
  serviceContainer: ServiceContainer,
  action: ActionType<typeof manualStockTakingItemRequired>
): Saga {
  const { stockTakingService, itemSearchService, handleSagaError } =
    serviceContainer;

  let itemId: string = "";
  let barcode: string = "";
  try {
    if ("barcode" in action.payload) {
      barcode = action.payload.barcode;
      const itemIdFromBarcode = yield getItemIdByBarcode(
        barcode,
        itemSearchService
      );
      if (!itemIdFromBarcode) {
        yield put(itemLoadInvalidBarcode(action.payload.barcode));
        return;
      }
      itemId = itemIdFromBarcode;
    } else if (
      "itemId" in action.payload &&
      typeof action.payload.itemId === "string"
    ) {
      itemId = action.payload.itemId;
    } else {
      throw new Error("Missing params.itemId");
    }

    const stockId = yield select(selectWarehouseId);
    const stockTakeId: string = yield call(
      getCurrentStockTakeId,
      serviceContainer
    );

    const getItemResponse: GetItemResponse = yield call(
      [stockTakingService, "getItem"],
      stockId,
      stockTakeId,
      itemId
    );

    yield put(itemLoaded(getItemResponse));
  } catch (e) {
    yield* handleSagaError(
      (errorCode) => `saga:stock-taking:getItemSaga:${errorCode}`,
      e,
      put(
        itemLoadFailed(
          toErrorActionPayload(e, {
            itemIdOrBarcode: itemId !== "" ? itemId : barcode,
          })
        )
      )
    );
  }
}
