import { Selector } from "../../types";

import { PreviousStockTakeItemInformationWithState } from "./previousStockTakeItemInformationSlice";

export const selectPreviousStockTakeItemInformation =
  (
    stockId: number,
    stockTakeVivaldiId: number,
    itemId: string
  ): Selector<PreviousStockTakeItemInformationWithState | undefined> =>
  (state) =>
    state.previousStockTakeItemInformation.items[
      `${stockId}-${stockTakeVivaldiId}-${itemId}`
    ];
