import barcodeScannerRoutes from "../barcode-scanning/barcodeScannerRoutes";
import itemSearchRoutes from "../item-search/itemSearchRoutes";
import { createRoutes } from "../routing/utils";

const stockTakingRoutes = createRoutes({
  stockTaking: {
    path: "/stock-taking?highlightVivaldiId",
    children: {
      manual: {
        path: "/manual?goto&clear&modal",
        children: {
          ...barcodeScannerRoutes,
          ...itemSearchRoutes,
          item: {
            path: "/item/:itemIdOrBarcode?autofocus&acceptQuantity&modal&continueTo&barcode",
            children: {
              remove: { path: "/remove" },
              diff: { path: "/diff?continueTo" },
            },
          },
          submit: {
            path: "/submit",
            children: {
              complete: { path: "/:stockTakeId" },
              error: { path: "/error" },
            },
          },
          remove: { path: "/:itemIdOrBarcode" },
        },
      },
      submitted: {
        path: "/submitted/:stockTakeId?tab",
      },
    },
  },
} as const);

export default stockTakingRoutes;
